<template>
  <b-container fluid>

    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ count }} Notifications</h4>
          </template>
          <template v-slot:headerAction>
            <div class="input-with-icon">
    <span class="icon is-small is-left" style="margin-top: 35px;">
      <i class="fas fa-search"></i>
    </span>
            <input type="search" class="form-control mr-5" v-model="searchQuery" @keyup="handleSearch"
              placeholder="Search" >
              </div>
          </template>
          <template v-slot:body>
            <b-row v-if="loading">
      <LoadingSpinner :loading="loading" />
    </b-row>
            <b-row v-else>
              <b-col md="12" class="table-responsive">
                <b-table bordered hover :items="rows" :fields="columns" :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc">
                  <template v-slot:cell(serialNumber)="data">
                    {{ (currentPage - 1) * itemsPerPage + data.index + 1 }}
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <b-row v-if="rows.length === 0">
              <b-col md="12">
                <h5 class="text-center mt-5 mb-5">{{ response_message }}</h5>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="itemsPerPage"
                  @change="onPageChange" align="center" v-if="rows.length"/>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { core } from '../../../config/pluginInit'
import axios from 'axios'
import constant, { decryptToken, response_message } from "@/config/constant";
import LoadingSpinner from "@/config/Pageloader.vue";
import { BPagination } from "bootstrap-vue";
export default {
  name: 'Notifications',
  components: { BPagination, LoadingSpinner },
  data() {
    return {
      columns: [
        { label: 'S. No.', key: 'serialNumber', class: 'text-left' },
        { label: 'Message', key: 'message', class: 'text-left', sortable: true },
        { label: 'Created Date', key: 'created_date', class: 'text-left', sortable: true },
      ],
      sortBy: '',
      sortDesc: false,
      count: 0,
      searchQuery: '',
      response_message: response_message,
      rows: [],
      currentPage: 1,
      itemsPerPage: 20, // Display 10 records per page
      totalRows: 0, // Total rows in API response
      loading: true, // Add a loading state
    }
  },
  methods: {
    handleSearch() {
      // Call fetchData with the searchQuery
      this.fetchData(this.currentPage, this.searchQuery);
      this.loading = true;
    },

    async fetchData(pageNumber, searchQuery = '') {
      // Fetch notification
      try {
        const token = localStorage.getItem('token');
        const key = 'enc';
        const decryptedToken = decryptToken(token, key);

        const response = await axios.get(constant.API_BASE_URL + 'network/notifications/', {
          params: {
            page: pageNumber, // Pass the page number parameter
            search: searchQuery, // Include the searchQuery parameter
            ordering: '-created_date'
          },
          headers: {
            'Authorization': 'Token ' + decryptedToken
          }
        });
        this.count = response.data.count
        this.rows = response.data.data.map(item => ({
          ...item,
          created_date: this.formatDate(item.created_date)
        }));

        this.totalRows = response.data.count; // Update totalRows with the total count from API
        this.loading = false;

      } catch (error) {
        this.rows = ""
        this.loading = false;
      }
    },
    formatDate(dateString) {
      // date change formate
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = this.getMonthName(date.getMonth()); // Get month name
      const day = String(date.getDate()).padStart(2, '0');
      const hours = date.getHours();
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const ampm = hours >= 12 ? 'PM' : 'AM';
      const formattedHours = hours % 12 || 12;
      return `${day}-${month}-${year} ${formattedHours}:${minutes} ${ampm}`;
    },
    getMonthName(monthIndex) {
      const monthNames = [
        "January", "February", "March",
        "April", "May", "June", "July",
        "August", "September", "October",
        "November", "December"
      ];
      return monthNames[monthIndex];
    },
    onPageChange(pageNumber) {
      this.currentPage = pageNumber;
      this.fetchData(pageNumber, this.searchQuery); // Fetch data for the new page
    }
  },
  mounted() {
    this.fetchData(this.currentPage); // Fetch initial data for page 1
    core.index();
  }
}
</script>